<template>
  <div class="login">
    <div class="container1">
      <div class="h1">
        <!-- <img src="../../assets/images/ceetree.png" alt=""> -->
        <div class="header">心理服务云平台</div>
        <!-- <div class="header">慈树健康心理服务云平台</div> -->
      </div>
      <div class="container">
      <div class="main">
        <el-row :gutter="24">
          <el-col :xs="0" :sm="12" :md="14">
            <div class="left">
              <img src="../../assets/images/left2.png" alt /></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="10">
            <div class="right">
              <h3>登录</h3>
              <!-- <h3>请使用Cee Tree账号登录</h3> -->
              <el-form :model="form" ref="form" :rules="rules">
                <el-form-item prop="username">
                  <el-input placeholder="请输入账号" v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input placeholder="请输入密码" v-model="form.password" type="password" :show-password="true"></el-input>
                </el-form-item>
                <el-form-item class="code" prop="code">
                  <el-col :span="12" style="padding-left: 0;">
                    <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
                  </el-col>
                  <el-col :span="9">
                     <img ref="code"  class="canvas" :src='codeUrl' alt="">
                  </el-col>
                  <el-col :span="3">
                    <i class="el-icon-refresh-right" @click="getImgCode"></i>
                  </el-col>
                </el-form-item>
                <!-- <el-form-item class="checkbox">
                  <el-col :span="12">
                    <el-checkbox></el-checkbox>
                    <span class="t1">记住密码</span>
                  </el-col>
                  <el-col :span="12" class="text_right">
                    <a href="/" class="t2">忘记密码?</a>
                  </el-col>
                </el-form-item> -->
                <el-form-item>
                  <el-button class="mbtn" type="primary" @click="submit">登录</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
      </div>
      <div class="footer">
        <!-- <span>版权所有 ©{{new Date().getFullYear()}} 深圳市慈树健康科技有限公司</span> -->
      </div>
    </div>
  </div>
</template>

<script>
  import {login ,userInfo,codeImg} from "@/api/api";
  export default {
    data() {
      return {
        codeUrl: "",
        len: 4,
        form: {
          code:"",//验证码值
          password: "",
          randomStr: "",//获取的验证码值
          username: "",
        },
         rules: {
          username: [{
            required: true,
            message: "请输入账号!"
          }, {
            min: 5,
            message: '用户名长度不低于5个字符!'
          }, {
            max: 30,
            message: '用户名长度不超过32个字符!'
          }],
          password: [{
            required: true,
            message: "请输入密码!"
          },
           {
            min: 6,
            message: '最少6位'
          }
          ],
        
        },
      };
    },
    watch: {
      "form.username"(val) {
        this.form.username = this.$util.trim(val);
      },
    },
    mounted() {
      if (this.$route.name != "login") {
        this.$router.push({
          name: "login"
        });
      }
       this.getImgCode()
    },
    methods: {
       // 获取验证码
      getImgCode() {
        this.form.code = ""
         this.form.randomStr = this.$util.randomLenNum(this.len, true)
         this.codeUrl = this.$util.baseUrl + codeImg(this.form.randomStr).url
      },
      // 登录按钮验证
      async submit() {
        let data = this.$util.superCopy(this.form)
        data.password = this.$util.encryption(data.password)
        await this.$request(login(data)).then((res) => {
          this.isSuccess(res)

        }).catch(err => {
          this.form.code = ""
          this.getImgCode()
        });
      },
      async isSuccess(res) {
        this.$util.message("suc", "登录成功");
        localStorage.setItem('access_token', 'Bearer ' + res.access_token);
        this.$router.push({
          name: "gauge"
        });
        await this.getUserInfo()
      },
      //获取用户信息
     async getUserInfo(){
       await this.$request(userInfo).then((res) => {
           if (res.code === 0) {
             this.$store.commit("setParam", {
                  userInof: res.data.sysUser
              });
           }
        })
      },
    },
  };
</script>

<style lang="less">
  .login {
    width: 100%;
    height: 100%;
    min-height: 500px;
    background: #ffffff;
    .container1{
       min-height: 760px;
       height: 77vh;
  
      .h1 {
        display: flex;
        align-items: center;
        height: 10vh;
        padding-left: 104px;
        padding-bottom: 10px;
        // min-height: 50px;
        // max-height: 110px;
        // line-height: 110px;
        img{
          height:40px;
          width: 167px;
          padding-right: 10px;
        }
        
        .header {
          font-size: 24px;
          font-weight: 500;
          color: #888888;
          // border-left:2px solid #888888 ;
          padding-left: 10px;
          margin-top: 10px;
       
        }
      }
    .container {
      width: 100%;
      background: url("../../assets/images/bg2.png") repeat;
      background-size: cover;
      background-attachment: local;
      .main {
        min-height: 500px;
        height: 72vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        >.el-row {
          margin-right: 0px !important;
          display: flex;
          align-items: center;
        }

        .left {
          padding-right: 129px;
          min-width: 590px;
          min-height: 413px;
          img {
          width: 100%;
          height: 100%;
          }
        }

        .right {
          width: 350px;
          height: 350px;
          background: #ffffff;
          padding: 36px 30px 0px 30px;
          box-shadow: 0px 0px 27px 0px rgba(51, 156, 255, 0.4);
          // box-sizing: border-box;
          .el-button {
            font-size: 20px;
          }

          h3 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 20px;
          }

          .mbtn {
            width: 100%;
            height: 48px;
            // line-height: 40px;
            font-size: 18px;
            // border-radius: 34px;
            margin-top: 20px;
          }

          .el-input__inner {
            height:45px;
            font-size: 16px;
          }
          .el-form-item__content {
            line-height: 24px;
        }

          .t1 {
            padding-left: 4px;
            font-size: 18px;
            color: #666666;
          }

          .t2 {
            font-size: 18px;
            color: #007aff;
          }

          .el-icon-refresh-right:before {
            font-size: 28px;
            color: #666666;
            margin-left: 4px;
            line-height: 40px;
          }

          img {
            width: 100%;
            height: 100%;
            padding-left: 16px;
          }


          .el-form-item {
            margin-bottom: 22px;
          }

          .code {
            margin-bottom: 0px;

            /deep/.el-col-12 {
              padding-left: 0 !important;
            }
          }

          .checkbox {
            margin-bottom: 18px;
          }
        }
      }


    }
          .footer {
        height: 21.5vh;
        // margin-top: 70px;
        min-height: 70px;
        max-height: calc(50vh - 500px);
        padding-bottom: 24px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        span {
          color: #666666;
          font-size: 16px;
        }
      }
    }
  }
</style>